/* Custom Fonts */
@font-face {
  font-family: Poppins;
  src: url('./assets/fonts/Poppins-Medium.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Poppins;
  src: url('./assets/fonts/Poppins-SemiBold.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  src: url('./assets/fonts/Poppins-Thin.ttf');
  font-weight: 400;
}

/* Default CSS */

/* All Elements on the Page  */
* {
  /* removes default browser settings */
  margin: 0; 
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Poppins', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
