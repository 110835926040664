.project {
    padding: 30px 0;
    position: relative;
    background: #42C092;
}

.project h2 {
    color: black;
    font-size: 45px;
    font-weight: 700;
    text-align: center;
}

.project p {
    color: black;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 56%;
}

.project .project-cards-row {
    justify-content: center;
}