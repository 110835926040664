.about {
  padding: 0 0 50px 0;
  position: relative;
  background: #42C092;
}
.about-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}