.option-container {
    display: inline-flex;
    align-items: center;
    padding-right: 20px;
    margin-bottom: 40px;
}

.circle-selection {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #000;
    margin-right: 5px;
    border-color: white;
    vertical-align: middle;
    cursor: pointer;
}

.filled-circle {
    background-color: white;
}

.selection-text {
    font-size: 16px;
    font-weight: bold; 
}

.skill-slider {
    width: 80%;
    margin: 0 auto;
    position: relative;
}