.social-media {
    margin-top: 0;
    padding: 40px 0;
    background: #42C092
}

.social-text {
    margin: 0;
    font-weight: 700;
    position: relative;
    color: white;
    padding-bottom: 20px;
    text-align: center;
}

.social-icon {
    display: flex;
    justify-content: center; 
}

.social-icon a {
    width: 60px;
    height: 60px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 2px solid black;
}

.social-icon a img {
    width: 90%;
    z-index: 1;
}

.social-icon a:hover {
    border-color: transparent;
}

.social-icon a .email-icon {
    width: 70%; 
}


