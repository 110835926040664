
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}

.proj-imgbx {
  height: 300px;
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #060060 -5.91%, #1739D1 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.proj-txtx:hover h4,
.proj-txtx:hover span {
  color: #AAF0D2;
}

.proj-txtx p {
  font-style: italic;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.proj-imgbx a {
  text-decoration: none;
  color: inherit;
}
  
  
  
