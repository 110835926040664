.banner {
    margin-top: 0;
    padding: 260px 0 100px 0;
    background: #AAF0D2
  }

  .banner .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 12px 14px;
    background: linear-gradient(90.21deg, rgba(6, 0, 96, 0.7) -5.91%, rgba(23, 57, 209, 0.7) 111.58%);   
    border-radius: 20px;
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;
  }

  .typewriter-wrapper {
    color: white;
    font-size: 65px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
    background: linear-gradient(90.21deg, rgba(6, 0, 96, 0.7) -5.91%, rgba(23, 57, 209, 0.7) 111.58%);    
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    padding: 10px;
  }

  .banner p {
    color: white;
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 90%;
    background: linear-gradient(90.21deg, rgba(6, 0, 96, 0.7) -5.91%, rgba(23, 57, 209, 0.7) 111.58%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    padding: 20px;
    margin: 0 auto; 
    text-align: center;
  }
  
  .banner button svg {
    font-size: 25px;
    margin-left: 10px;
    transition: 0.3s ease-in-out;
    line-height: 1;
  }
  .banner button:hover svg {
    margin-left: 25px;
  }
  .banner img {
    animation: updown 3s linear infinite;
    width: 300px;
    height: 300px;
  }
  @keyframes updown {
      0% {
          transform: translateY(-20px);
      }
      50% {
          transform: translateY(20px);
      }
      100% {
          transform: translateY(-20px);
      }
  }
  .txt-rotate > .wrap {
    border-right: 0.08em solid #666;
  }
  
  