.blockquote {
  margin: 0;
  padding: 20px;
  font-size: 24px;
  font-weight: 700;
  font-style: italic;
  position: relative;
  color: black;
}
  
footer.blockquote-footer {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 700;
  font-style: italic;
  position: relative;
  color: black;
}
  